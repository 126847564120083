import React, { useState, useEffect } from "react";
import {
  Button, Carousel, Col, Container, Modal, Row,
} from "react-bootstrap";

import "./index.css";

import CardNoticeComponent from "../../components/cardNoticeComponent";
import Footer from "../footer";
import Header from "../header";

import BannerApostilage from "../../assets/banners/apostilage.jpg";
import BannerDut from "../../assets/banners/dut.jpg";
import BannerEscrituraProcuracao from "../../assets/banners/escritura-procuracao.jpg";
import BannerHome from "../../assets/banners/home.jpg";
import BannerPasswordRemoval from "../../assets/banners/password-removal.jpg";
import BannerQueries from "../../assets/banners/queries.jpg";
import BannerSatisfactionSurvey from "../../assets/banners/satisfaction-survey.jpg";
import BannerMarketing from "../../assets/banners/banner-marketing.png";
import BannerNotificacao from "../../assets/notificacao.jpg";

import Contratando from "../../assets/contratando.jpg";
import Aviso from "../../assets/aviso.jpg";
import Aedo from "../../assets/aedo.jpg";

import Notice from "../../notice";
import ImagesApi from "../../images";

function Home() {
  const [title] = useState("Página inicial - Tabelionato Camboriú");
  const [page] = useState("/");
  const [notices, setNotices] = useState([]);
  const [banner, setBanner] = useState(false);

  const useMountEffect = (fun) => useEffect(fun, []);

  const handleOnload = () => {
    document.title = title;
  };

  const findNotices = async () => {
    const response = await Notice.find({ page: 1 });
    if(response.success) {
      if(response.data.docs.length > 3) {
        return setNotices([response.data.docs[0], response.data.docs[1], response.data.docs[2]]);
      }
      return setNotices(response.data.docs);
    }
  };

  const findBanner = async () => {
  	/*
	  	<Row>
                <Col>
                  <img src={BannerAviso} alt="Banner aviso." />
                </Col>
              </Row>
  	*/
    //setBanner(Banner.isLoad());
    /*<Container>
              <Row>
                <Col>
                  <h3 class="default-sub-title text-center w-100">Comunicado</h3>
                  <p>Senhores clientes, informamos que no próximo dia <strong>15/02/2021 (segunda-feira)</strong>, <strong>16/02/2021 (terça-feira)</strong> e <strong>17/02/2021 (quarta-feira)</strong>, <strong>haverá expediente normal</strong>, durante o <strong>Carnaval.</strong></p>
                  <p>Atenciosamente</p>
                </Col>
              </Row>
            </Container>
            <h3 class="default-sub-title text-center w-100">Comunicado</h3>
                  <p>Diante do aumento de número de infectados em nosso país e seguindo as orientações do Ministério da Saúde, RECOMENDAMOS que o encaminhamento dos ATOS NOTARIAIS sejam realizados de maneira online, ou seja, atas notariais, procurações,  escrituras declaratórias, testamentos, inventários, divórcios e escrituras com valor como compra e venda, doação e permuta - <strong>WhatsApp (47) 9 9172 7671 ou via e-mail tabelionatocamboriu@hotmail.com</strong>, e <u>somente compareçam para coleta das assinaturas mediante agendamento</u>.</p>
                  <p>Utilizem nosso aplicativo ( tabelionato camboriu ) para retirada de senhas e evitem filas e aglomerações.</p>
                  <p>Preferencialmente, leve a sua caneta quando for ao cartório (evite compartilhamento de objetos)</p>
                  <p>Evite horários de PICO do cartório (entre 11h30 e 14h)</p>
                  <p>Qualquer dúvida entrem em contato conosco (47) 3365.4336.</p>
                  <p>Seja compreensivo. O momento exige a colaboração de todos para que os serviços continuem sendo prestados de forma eficaz. </p>

            */
    setBanner(true);
  };

  useMountEffect(() => {
    findNotices();
    findBanner();
  });

  return (
    <div onLoad={handleOnload}>
      <Header page={page} />
      <main>
        <section id="section-carousel" className="section-carousel">
          <Carousel>
            <Carousel.Item style={{ background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${BannerHome})` }}>
              <Carousel.Caption>
                <Container>
                  <Row>
                    <Col xs={12} lg={6}>
                      <p className="default-description slider slider-col">
                        <strong>Atendimento:</strong>
                        <br />
                        <br />
                        Segunda a sexta, das 08:00h às 18:00h
                        <br />
                        <strong>Sem fechar para o almoço.</strong>
                      </p>
                    </Col>
                    <Col xs={12} lg={6}>
                      <p className="default-description slider slider-col">
                        <strong>Endereço:</strong>
                        <br />
                        <br />
                        Rua Anastácio José Pereira, 87, Centro.
                        <br />
                        <strong>47 33654336</strong> - <strong>47 33655177</strong>
						<br />
                        <strong>47 991727671</strong> - <strong>Procuracoes/Protestos</strong>
                        <br />
                        <strong>47 991724336</strong> - <strong>Escrituras</strong>
                        <br />
                        <strong>47 991726820</strong> - <strong>Autenticações/Reconhecimento de Assinaturas</strong>
                        <br />
                        <strong>Aceitamos cartão de débito</strong>
                      </p>
                    </Col>
                  </Row>
                </Container>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item style={{ background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${BannerPasswordRemoval})` }}>
              <div className="section-content">
                <div className="section-content-center">
                  <a className="default-link-absolute" href="/retirada-de-senhas">&nbsp;</a>
                  <h3 className="default-title slider">
                    <span className="default-title-sub-a">Retirada de senhas</span>
                    <br />
                    <span className="default-title-sub-b">O fim das filas.</span>
                  </h3>
                  <p className="default-description slider">
                    Retire sua senha e acompanhe o andamento da fila online clicando aqui.
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item style={{ background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${BannerSatisfactionSurvey})` }}>
              <div className="section-content">
                <div className="section-content-center">
                  <a className="default-link-absolute" href="/pesquisa-de-satisfacao">&nbsp;</a>
                  <h3 className="default-title slider">
                    <span className="default-title-sub-a">Pesquisa de satisfação</span>
                    <br />
                    <span className="default-title-sub-b">Participe da melhoria.</span>
                  </h3>
                  <p className="default-description slider">
                    Deixe o seu feedback clicando aqui para podermos melhorar sempre!
                  </p>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
        <section className="section-default section-online-services">
          <Container>
            <Row>
              <Col xs={12}>
                <h4 className="default-sub-title">E-Notariado</h4>
                <div className="default-sub-title-separator" />
                <p className="default-sub-description text-center">
                  Faça seu certificado no e-notariado, de forma gratuita, no Tabelionato de Camboriú, ou caso não esteja em Camboriú, dirija-se a um Tabelionato credenciado. Lista no site &nbsp;
                  <a href="www.e-notariado.org.br" target="_blank">www.e-notariado.org.br</a>
                  .
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section-default section-services">
          <div className="container-fluid">
            <Row>
              <Col className="section-services-col-a" xs={12} style={{ background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${BannerEscrituraProcuracao})` }}>
                <h4 className="default-sub-title light">Escritura e Procuração Online</h4>
                <div className="default-sub-title-separator half light" />
                <p className="default-sub-description light text-center">Agora é possível fazer escrituras e procurações públicas online.</p>
                <br />
                <p className="default-sub-description light text-center">
                  <Button as="a" href="/escritura-e-procuracao-online" variant="light">Tenho interesse!</Button>
                </p>
              </Col>
            </Row>
          </div>
        </section>
        <section className="section-default section-our-services">
          <Container>
            <Row>
              <Col xs={12}>
                <h4 className="default-sub-title">Nossos serviços</h4>
                <div className="default-sub-title-separator" />
                <p className="default-sub-description text-center">
                  Entenda um pouco mais sobre os nossos serviços acessando a página de&nbsp;
                  <a href="/nossos-servicos">nossos serviços</a>
                  .
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section-default section-services">
          <div className="container-fluid">
            <Row>
              <Col className="section-services-col-a" xs={12} lg={6} style={{ background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${BannerApostilage})` }}>
                <h4 className="default-sub-title light">Apostilamento</h4>
                <div className="default-sub-title-separator half light" />
                <p className="default-sub-description light text-center">Informamos que, este Tabelionato de Notas de Camboriú/SC, já conta com o serviço de APOSTILAMENTO de documentos para validade no exterior. Caso tenha interesse nesse serviço, nos informe através do presente email, para que possamos lhe prestar todas as informações.</p>
                <br />
                <p className="default-sub-description light text-center">
                  <Button as="a" href="/contato" variant="light">Tenho interesse!</Button>
                </p>
              </Col>
              <Col className="section-services-col-b" xs={12} lg={6} style={{ background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${BannerDut})` }}>
                <h4 className="default-sub-title light">Comunicação de venda - DUT</h4>
                <div className="default-sub-title-separator half light" />
                <p className="default-sub-description light text-center">Ao reconhecer a assinatura no Certificado de Registro do Veículo - CRV (DUT), faça a comunicação de venda aqui no Tabelionato.</p>
              </Col>
            </Row>
          </div>
        </section>
        <section className="section-default section-notices">
          <Container>
            <Row>
              <Col>
                <h4 className="default-sub-title">Últimas notícias</h4>
                <div className="default-sub-title-separator" />
                <p className="default-sub-description text-center">
                  Fique por dentro últimas publicações ou acesse a página de&nbsp;
                  <a href="/noticias/1">notícias</a>
                  &nbsp;para ver todas as publicações.
                </p>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              {
                notices.map(item => (
                  <Col className="notices-col" xs={12} md={6} lg={4} key={item.id}>
                    <CardNoticeComponent
                      description={Notice.getContentPrev({ content: item.content })}
                      img={ImagesApi.render({ image: item.images[0]})}
                      title={item.title}
                      url={`/noticia/${item.id}`}
                      date={Notice.getDate({ date: item.createdAt })}
                    />
                  </Col>
                ))
              }
              {
                notices.length === 0 ? (
                  <Col className="notices-col" xs={12}>
                    <p className="default-sub-description text-center">Nenhum resultado encontrado.</p>
                  </Col>
                ) : ""
              }
            </Row>
          </Container>
        </section>
        <section className="section-default section-others">
          <div className="container-fluid">
            <Row>
              <Col className="section-queries" xs={12} lg={4} style={{ background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${BannerPasswordRemoval})` }}>
                <h4 className="default-sub-title light">Retirada de senhas</h4>
                <div className="default-sub-title-separator half light" />
                <p className="default-sub-description light text-center">
                  <Button as="a" href="/retirada-de-senhas" variant="light">Retirar senha!</Button>
                </p>
              </Col>
              <Col className="section-queries" xs={12} lg={4} style={{ background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${BannerQueries})` }}>
                <h4 className="default-sub-title light">Consultas</h4>
                <div className="default-sub-title-separator half light" />
                <p className="default-sub-description light text-center">
                  <Button as="a" href="/consultas" variant="light">Solicitar consulta!</Button>
                </p>
              </Col>
              <Col className="section-satisfaction-survey" xs={12} lg={4} style={{ background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${BannerSatisfactionSurvey})` }}>
                <h4 className="default-sub-title light">Pesquisa de satisfação</h4>
                <div className="default-sub-title-separator half light" />
                <p className="default-sub-description light text-center">
                  <Button as="a" href="/pesquisa-de-satisfacao" variant="light">Deixar feedback!</Button>
                </p>
              </Col>
            </Row>
          </div>
        </section>
        <Modal
          size="lg"
          show={banner}
          onHide={() => setBanner(false)}
          aria-labelledby="banner-marketing"
        >
          <Modal.Header closeButton>
            <Modal.Title id="banner-marketing" className="default-sub-title text-center w-100">
              Seja bem-vindo!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="zerar-padding">
          	<Container>
								{/*<Row>
								  <Col>
								    <h3 class="default-sub-title text-center w-100">Comunicado</h3>
								    <p>Senhores clientes, informamos que no próximo dia <strong>12/10/2023 (quinta-feira)</strong>, <strong>não haverá expediente</strong>, durante o <strong>Feriado de Nossa Senhora Aparecida.</strong><br />No dia <strong>13/09/2023 (sexta-feira)</strong>, <strong>haverá expediente normal</strong>.</p>
								    <p>Atenciosamente</p>
								  </Col>
								</Row><Row>
              	<Col>
		              <img src={Aviso} alt="Banner aviso carnaval." />
								</Col>
              </Row>*/}
              <Row>
              	<Col>
		              <img style={{ marginBottom: "20px" }} src={Aviso} alt="Banner aviso feriado." />
								</Col>
              </Row>
              <Row>
              	<Col>
		              <img style={{ marginBottom: "20px" }} src={Contratando} alt="Banner aviso vagas." />
								</Col>
              </Row>
              <Row>
              	<Col>
		              <img src={Aedo} alt="Banner Aedo." />
								</Col>
              </Row>
          	  <Row>
              	<Col>
	              	<a href="/retirada-de-senhas">
		              	<p className="default-sub-description-banner default-sub-description text-center w-100">Retire sua senha e acompanhe o andamento da fila online clicando aqui.</p>
		              	<img src={BannerMarketing} alt="Banner divulgação aplicativos." />
									</a>
								</Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </main>
      <Footer page={page} />
    </div>
  );
}

export default Home;
